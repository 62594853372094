import { useParams } from 'react-router-dom';

// function technology() {
//   let { name } = useParams();
//   console.log("Id", name);

//   return (
//     <>
//       This is technology page ${name}
//     </>
//   );
// }

// export default technology;

import Technology from "pages/Technology";

export default function technology({ color }) {
  let { name } = useParams();
  console.log("name", name, color);
  return <Technology value={name} color={"#189AB4"} />;
}
