
const imagesPrefix =
  "https://s3.ap-south-1.amazonaws.com/techbuilder.in/public-assets/logos";

export default [
  {
    items: [
      {
        image: `${imagesPrefix}/chat.jpg`,
        name: "Chat App",
        // count: 30,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: `${imagesPrefix}/micro_service.png`,
        name: "Basic Microservice With NodeJs",
        // count: 20,
        // pro: true,
      },
      {
        image: `${imagesPrefix}/app_server.jpg`,
        name: "Simple App Server With Express",
        // count: 60,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: `${imagesPrefix}/app_server.jpg`,
        name: "Simple App Server With Express",
        // count: 60,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: `${imagesPrefix}/app_server.jpg`,
        name: "Simple App Server With Express",
        // count: 60,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: `${imagesPrefix}/app_server.jpg`,
        name: "Simple App Server With Express",
        // count: 60,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: `${imagesPrefix}/app_server.jpg`,
        name: "Simple App Server With Express",
        // count: 60,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
    ],
  },
];
