
const imagesPrefix =
  "https://s3.ap-south-1.amazonaws.com/techbuilder.in/public-assets/logos";

export default [
  // {
  //   title: "Design Blocks",
  //   description: "A selection of 45 page sections that fit perfectly in any combination",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/headers.jpg`,
  //       name: "Page Headers",
  //       count: 10,
  //       route: "/sections/page-sections/page-headers",
  //     },
  //     {
  //       image: `${imagesPrefix}/features.jpg`,
  //       name: "Features",
  //       count: 14,
  //       route: "/sections/page-sections/features",
  //     },
  //     {
  //       image: `${imagesPrefix}/pricing.jpg`,
  //       name: "Pricing",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/faq.jpg`,
  //       name: "FAQ",
  //       count: 1,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/blogs.jpg`,
  //       name: "Blog Posts",
  //       count: 11,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/testimonials.jpg`,
  //       name: "Testimonials",
  //       count: 11,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/teams.jpg`,
  //       name: "Teams",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/stats.jpg`,
  //       name: "Stats",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/call-to-action.jpg`,
  //       name: "Call to Actions",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/projects.jpg`,
  //       name: "Applications",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/logo-area.jpg`,
  //       name: "Logo Areas",
  //       count: 4,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/footers.jpg`,
  //       name: "Footers",
  //       count: 10,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/general-cards.jpg`,
  //       name: "General Cards",
  //       count: 9,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/content-sections.jpg`,
  //       name: "Content Sections",
  //       count: 8,
  //       pro: true,
  //     },
  //   ],
  // },
  // {
  //   title: "Navigation",
  //   description: "30+ components that will help go through the pages",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/navbars.jpg`,
  //       name: "Navbars",
  //       count: 4,
  //       route: "/sections/navigation/navbars",
  //     },
  //     {
  //       image: `${imagesPrefix}/nav-tabs.jpg`,
  //       name: "Nav Tabs",
  //       count: 2,
  //       route: "/sections/navigation/nav-tabs",
  //     },
  //     {
  //       image: `${imagesPrefix}/pagination.jpg`,
  //       name: "Pagination",
  //       count: 3,
  //       route: "/sections/navigation/pagination",
  //     },
  //   ],
  // },
  // {
  //   title: "Input Areas",
  //   description: "50+ elements that you need for text manipulation and insertion",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/newsletters.jpg`,
  //       name: "Newsletters",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/contact-sections.jpg`,
  //       name: "Contact Sections",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/forms.jpg`,
  //       name: "Forms",
  //       count: 3,
  //       route: "/sections/input-areas/forms",
  //     },
  //     {
  //       image: `${imagesPrefix}/inputs.jpg`,
  //       name: "Inputs",
  //       count: 6,
  //       route: "/sections/input-areas/inputs",
  //     },
  //   ],
  // },
  {
    title: "Attention Catchers",
    description: "100+ Fully coded apps which are accessible to download in just one click",
    items: [
      {
        image: `${imagesPrefix}/app_server.jpg`,
        name: "App Servers",
        count: 30,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: `${imagesPrefix}/micro_service.png`,
        name: "Microservices",
        count: 20,
        // pro: true,
      },
      {
        image: `${imagesPrefix}/utility.png`,
        name: "Utility Apps",
        count: 60,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      // {
      //   image: `${imagesPrefix}/modals.jpg`,
      //   name: "Modals",
      //   count: 5,
      //   route: "/sections/attention-catchers/modals",
      // },
    ],
  },
  // {
  //   title: "Elements",
  //   description: "80+ carefully crafted small elements that come with multiple colors and shapes",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
